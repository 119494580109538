import React, { useState, useEffect, useRef } from "react";
import Helmet from "react-helmet";
import throttle from "lodash/throttle";
import track from "react-tracking";

import Header from "@components/Header";
import Footer from "@components/FooterNew";
import { createCss } from "./styles";
import META from "./meta";
import { SearchInput } from "./SearchInput";
import { t } from "@i18n";
import SupportForm from "@components/SupportFormNew";
import { AboutUs } from "./AboutUsSection";
import { OurServicesBlock } from "./OurServicesBlock";
import { AboutBlock } from "./AboutBlock";
import { FaqBlock } from "./FaqBlock";
import { useViewport } from "@hooks/useViewport";
import Typography from "@ui-kit/Typography";

const Homepage: React.FC = () => {
  const { isMobile } = useViewport();
  const [scrollPosition, setScrollPosition] = useState(0);
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const css = createCss();

  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = throttle(() => {
    setScrollPosition(Math.round(window.scrollY));
  }, 300);

  return (
    <>
      <div data-test="homepage" css={css.root}>
        <Helmet {...META} />
        <Header
          fixed
          homepage={{
            underSearch:
              searchInputRef.current !== null &&
              scrollPosition >
                searchInputRef.current.getBoundingClientRect().y -
                  document.body.getBoundingClientRect().top -
                  10,
          }}
        />

        <div css={css.lightBlackBackground}>
          <div css={css.mainBlock}>
            <div css={css.mainBlockContent}>
              <div>
                <h1 css={css.mainHeading}>{t("homepage.pageTitle")}</h1>

                <div css={css.mainSubheading}>{t("homepage.pageSubtitle")}</div>
              </div>

              <div ref={searchInputRef}>
                <SearchInput />
              </div>
            </div>
          </div>
        </div>

        <AboutUs />

        <div css={css.goldBackground}>
          <div css={css.pageContainer}>
            <div css={css.parentPassTitleWrapper}>
              <Typography
                variant={isMobile ? "h2" : "h1"}
                align="center"
                color="textPrimary"
              >
                PARENT PASS
              </Typography>
            </div>
          </div>
        </div>

        <AboutBlock />
        <OurServicesBlock />

        <div css={css.lightBlackBackground} id="contact-section">
          <div css={css.pageContainer}>
            <div css={css.supportWrapper}>
              <div className={"gold support"} css={css.sectionHeading}>
                Have questions? Contact us
              </div>
              <SupportForm color="primary" />
            </div>
          </div>
        </div>
      </div>
      <Footer withSponsors />
    </>
  );
};

export default track({ page: "Homepage" })(Homepage);
