import React from "react";

import Button from "@ui-kit/Button";
import Typography from "@ui-kit/Typography";
import { t } from "@i18n";
import { createCss } from "../styles";
import { useViewport } from "@hooks/useViewport";

export const OurServicesBlock: React.FC = () => {
  const css = createCss();
  const { isMobile } = useViewport();

  return (
    <div css={css.goldBackground} className="services">
      <div css={css.pageContainer}>
        <div css={css.splittedSection}>
          <div></div>
          <div>
            <Button
              variant="contained"
              color="black"
              size="large"
              fullWidth={isMobile}
              link={{
                pathname: "/support",
                search: "?topic=employee-benefit-plans",
              }}
              className="brochure-button"
              name="brochureButton"
            >
              Request Parent Pass Brochure
            </Button>

            <div className="black" css={css.sectionHeading}>
              {t("homepage.ourServicesBlock.title")}
            </div>
            <Typography
              className="black"
              css={css.highlightedParagraph}
              variant="inherit"
              component="div"
            >
              <Typography variant="inherit" align="justify" paragraph>
                {t("homepage.ourServicesBlock.paragraph1")}
              </Typography>
              <Typography className="item" align="justify" gutterBottom>
                {t("homepage.ourServicesBlock.item1")}
              </Typography>
              <Typography className="item" align="justify" gutterBottom>
                {t("homepage.ourServicesBlock.item2")}
              </Typography>
              <Typography className="item" align="justify" gutterBottom>
                {t("homepage.ourServicesBlock.item3")}
              </Typography>
              <Typography className="item" align="justify" gutterBottom>
                {t("homepage.ourServicesBlock.item4")}
              </Typography>
              <Typography className="item" align="justify" gutterBottom>
                {t("homepage.ourServicesBlock.item5")}
              </Typography>
              <Typography className="item" align="justify" gutterBottom>
                {t("homepage.ourServicesBlock.item6")}
              </Typography>
              <Typography className="item" align="justify" gutterBottom>
                {t("homepage.ourServicesBlock.item7")}
              </Typography>
              <Typography className="item" align="justify" gutterBottom>
                {t("homepage.ourServicesBlock.item8")}
              </Typography>
              <Typography className="item" align="justify" gutterBottom>
                {t("homepage.ourServicesBlock.item9")}
              </Typography>
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};
