import React, { useState } from "react";
import { useForm, Controller, FieldError } from "react-hook-form";
import Grid from "@material-ui/core/Grid";

import Button from "@ui-kit/Button";
import { Input, OutlinedInput } from "@ui-kit/InputFields";
import Select from "@ui-kit/Select";
import { ControlledPhoneInput } from "@ui-kit/PhoneInput";
import useStyles from "./styles";
import { ReactGAEventWait } from "@helpers/ga";
import { required, email } from "@validators";
import { SUPPORT_API, Models } from "@services/api";
import { SupportFormProps } from "./types";
import { Topics } from "./topics";
import { useViewport } from "@hooks/useViewport";
import { expectServerError } from "@helpers/serverError";
import { useNotificationStore } from "@store/NotificationStore";

type FormFields = {
  email: string;
  companyName: string;
  description: string;
  phone: Models.Phone;
  topic: string;
};

const instanceOfFormFields = (field: string): field is keyof FormFields => {
  return (
    field === "email" ||
    field === "companyName" ||
    field === "description" ||
    field === "phone" ||
    field === "topic"
  );
};

const SupportForm: React.FC<SupportFormProps> = ({ color }) => {
  const [, { setNotification }] = useNotificationStore();
  const { isMobile } = useViewport();
  const { handleSubmit, control, errors, setError, reset, formState } =
    useForm<FormFields>();
  const [showSuccess, setShowSuccess] = useState(false);
  const classes = useStyles({ color });

  const submitForm = handleSubmit(async ({ phone, ...values }) => {
    try {
      ReactGAEventWait({
        action: "Submit",
        category: "SupportForm",
      });

      await SUPPORT_API.supportContactUs({
        supportContactUs: {
          ...values,
          phone: phone.number,
          phoneCountry: phone.country,
        },
      });

      ReactGAEventWait({
        action: "SubmitSuccess",
        category: "SupportForm",
      });

      reset();
      setShowSuccess(true);
    } catch (e) {
      ReactGAEventWait({
        action: "SubmitError",
        category: "SupportForm",
      });

      const errors = expectServerError(e);

      errors.forEach((e) => {
        const paths = e.source.pointer?.split("/");

        if (paths && instanceOfFormFields(paths[1])) {
          setError(paths[1], {
            message: e.title,
            type: "manual",
          });
        } else {
          setNotification({
            message: e.title,
            type: "error",
          });
        }
      });
    }
  });

  return (
    <Grid
      className={classes.gridContainer}
      container
      spacing={3}
      data-test="support-form"
    >
      <Grid item xs={12} className={classes.grid}>
        <Controller
          render={(controllerProps) => (
            <Select
              {...controllerProps}
              error={(errors.topic as FieldError)?.message}
              className={classes.field}
              items={Topics}
              labelProp="name"
              displayEmpty
            />
          )}
          rules={required()}
          name="topic"
          control={control}
          defaultValue=""
        />
      </Grid>
      <Grid item xs={isMobile ? 12 : 6} className={classes.grid}>
        <ControlledPhoneInput
          error={(errors.phone as FieldError)?.message}
          placeholder="Contact phone number"
          className={classes.field}
          name="phone"
          control={control}
          required
        />
      </Grid>
      <Grid item xs={isMobile ? 12 : 6} className={classes.grid}>
        <Controller
          as={
            <Input
              error={errors.email?.message}
              placeholder="Contact email"
              data-test="email-field"
              type="email"
              className={classes.field}
            />
          }
          rules={{
            ...required(),
            ...email(),
          }}
          name="email"
          control={control}
          defaultValue=""
        />
      </Grid>
      <Grid item xs={12} className={classes.grid}>
        <Controller
          as={
            <Input
              error={errors.companyName?.message}
              data-test="company-name-field"
              placeholder="Company name"
              className={classes.field}
            />
          }
          rules={required()}
          name="companyName"
          control={control}
          defaultValue=""
        />
      </Grid>
      <Grid item xs={12} className={classes.grid}>
        <Controller
          as={
            <OutlinedInput
              error={errors.description?.message}
              multiline
              placeholder="Drop us a note"
              data-test="description-field"
              rowsMax="4"
              maxChars={750}
              inputClasses={{
                input: classes.textarea,
              }}
              className={classes.field}
            />
          }
          rules={required()}
          name="description"
          control={control}
          defaultValue=""
        />
      </Grid>
      {showSuccess && (
        <div className={classes.successMessage} data-test="success-message">
          Request submitted
        </div>
      )}

      <Grid item xs={12} className={classes.grid}>
        <Button
          variant="contained"
          color="yellow"
          size="large"
          data-test="submit-btn"
          onClick={submitForm}
          type="submit"
          className={classes.blackButton}
          loading={formState.isSubmitting}
        >
          Submit
        </Button>
      </Grid>
    </Grid>
  );
};

export default SupportForm;
