import React, { useState, useEffect, useMemo, useCallback } from "react";
import { css as emotionCss } from "@emotion/css";
import { useHistory } from "react-router-dom";
import qs from "query-string";
import mapValues from "lodash/mapValues";
import groupBy from "lodash/groupBy";

import { createCss } from "../styles";
import Select from "@ui-kit/Select";
import { ExtendedState, StateCounters } from "../types";
import { StateSlider } from "../StateSlider";
import { Models, FACILITY_API, GEO_API } from "@services/api";
import Typography from "@ui-kit/Typography";
import { t } from "@i18n";
import { theme } from "@theme";
import { AustraliaBlock } from "../AustraliaBlock";

const selectForm = emotionCss({
  "& label": {
    color: "white !important",
  },

  "& input": {
    color: "white !important",
  },

  "& svg": {
    color: "white !important",
  },

  [theme.mediaRequests.notMobile]: {
    marginTop: 109,
  },
});

export const AboutUs: React.FC = () => {
  const history = useHistory();
  const [stateCounters, setStateCounters] = useState<StateCounters>();
  const [activeCountry, setActiveCountry] = useState("US");
  const css = createCss();

  const getCounters = useCallback(async () => {
    const [stateCountersResponse, statesResponse] = await Promise.all([
      FACILITY_API.facilitiesCountByState(),
      GEO_API.geoStatesIndex(),
    ]);

    const extendedStates = statesResponse.data.data
      .filter((c) => c.country === "US")
      .reduce((acc: Record<string, ExtendedState>, s) => {
        acc[s.id] = { ...s, counters: null };

        return acc;
      }, {} as Record<string, ExtendedState>);

    const countByState = groupBy(
      stateCountersResponse.data.data.filter((c) => c.country === "US"),
      (s) => s.state,
    );

    const counters = mapValues(extendedStates, (s) => {
      const stateInfo = countByState[s.id];

      if (!stateInfo) {
        return s;
      }

      const daycareCenters =
        stateInfo.find((s) => s.type === Models.FacilityTypeID.DaycareCenter)
          ?.count || 0;
      const privateSchools =
        stateInfo.find((s) => s.type === Models.FacilityTypeID.PrivateSchool)
          ?.count || 0;
      const preschools =
        stateInfo.find((s) => s.type === Models.FacilityTypeID.PreSchool)
          ?.count || 0;

      return {
        ...s,
        counters: {
          [Models.FacilityTypeID.DaycareCenter]: daycareCenters,
          [Models.FacilityTypeID.PrivateSchool]: privateSchools,
          [Models.FacilityTypeID.PreSchool]: preschools,
          [Models.FacilityTypeID.Oshc]: 0,
          total: privateSchools + daycareCenters + preschools,
        },
      };
    });

    const ausCounters = stateCountersResponse.data.data
      .filter((c) => c.country === "AUS")
      .reduce(
        (acc, cur) => {
          (acc as any)[cur.type] += cur.count;
          acc.total += cur.count;

          return acc;
        },
        {
          [Models.FacilityTypeID.DaycareCenter]: 0,
          [Models.FacilityTypeID.PrivateSchool]: 0,
          [Models.FacilityTypeID.PreSchool]: 0,
          [Models.FacilityTypeID.Oshc]: 0,
          total: 0,
        },
      );

    counters.AUS = {
      defaultCoords: null,
      id: "AUS",
      name: "Australia",
      counters: ausCounters,
      imageUrl: "",
    };

    setStateCounters(counters);
  }, [setStateCounters]);

  useEffect(() => {
    void getCounters();
  }, [getCounters]);

  const goToMap = useCallback(
    (coords: number[] | null) => {
      if (coords === null) {
        return;
      }

      history.push({
        pathname: "/map",
        search: qs.stringify({
          lat: coords[1],
          lng: coords[0],
        }),
      });
    },
    [history],
  );

  const RenderStatesSlider = useMemo(() => {
    if (!stateCounters) {
      return null;
    }

    if (activeCountry === "AUS") {
      return <AustraliaBlock counters={stateCounters} onClick={goToMap} />;
    }

    return <StateSlider counters={stateCounters} onClick={goToMap} />;
  }, [stateCounters, activeCountry]);

  return (
    <div css={css.lightBlackBackground}>
      <div css={css.pageContainer}>
        <div css={css.splittedSection}>
          <div className="mobile-first">
            <div css={css.sectionHeading} className="gold">
              {t("homepage.pdoSection.title")}
            </div>
            <Typography
              css={css.highlightedParagraph}
              variant="inherit"
              component="div"
              align="justify"
            >
              {t("homepage.pdoSection.paragraph1")}
            </Typography>
            <Typography
              css={css.highlightedParagraph}
              variant="inherit"
              component="div"
              align="justify"
            >
              {t("homepage.pdoSection.paragraph2")}
            </Typography>
            <Typography
              css={css.highlightedParagraph}
              variant="inherit"
              component="div"
              align="justify"
            >
              {t("homepage.pdoSection.paragraph3")}
            </Typography>
          </div>

          <div className="mobile-second">
            {stateCounters && (
              <Select
                css={css.field}
                formControlClasses={{
                  root: selectForm,
                }}
                items={[
                  { id: "US", label: "United States" },
                  { id: "AUS", label: "Australia" },
                ]}
                onChange={(event) => {
                  setActiveCountry(event.value.id);
                }}
                labelProp="label"
                value={activeCountry}
              />
            )}

            {RenderStatesSlider}
          </div>
        </div>
      </div>
    </div>
  );
};
