import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";
import backgroundImage from "@images/background.jpg";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  statesSlider: (theme) => ({
    [theme.mediaRequests.mobile]: {
      margin: "0 -20px",
    },

    "& .slick-arrow": {
      color: "white !important",
      height: 30,
      width: 30,
      zIndex: 10,

      "&:before": {
        color: "inherit",
        fontSize: 28,
      },

      "&.slick-prev": {
        left: -35,
      },

      "&.slick-next": {
        right: -35,
      },

      "&.slick-disabled": {
        display: "none!important",
      },
    },

    "& div": {
      outline: "none",
    },
  }),

  stateSliderWrapper: {
    marginTop: 40,
  },

  researchItem: (theme) => ({
    cursor: "pointer",
    fontWeight: 700,
    height: 150,
    position: "relative",

    [theme.mediaRequests.notMobile]: {
      margin: 2,
      width: "calc(50% - 4px)",
    },

    [theme.mediaRequests.mobile]: {
      margin: "0 5px",
    },

    "&.empty": {
      alignItems: "center",
      background: `url(${backgroundImage})`,
      color: "white",
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
    },

    "& .image-container": {
      backgroundPosition: "center",
      backgroundSize: "100% 100%",
      height: 150,
      position: "relative",
      width: "100%",

      "&:after": {
        background:
          "radial-gradient( ellipse at center,rgba(0,0,0,0.1) 0%,rgba(0,0,0,0) 100% ) rgba(0,0,0,0.3)",
        bottom: 0,
        content: "''",
        display: "block",
        left: 0,
        position: "absolute",
        right: 0,
        top: 0,
      },
    },

    "& .counters-container": {
      bottom: 0,
      color: "white",
      fontSize: 14,
      left: 0,
      lineHeight: "24px",
      padding: 10,
      position: "absolute",
      textAlign: "center",
      width: "100%",
    },

    "& .text-container": {
      color: "white",
      left: "50%",
      position: "absolute",
      textAlign: "center",
      textShadow: "rgba(0, 0, 0, .25) 0px 2px 2px",
      top: 10,
      transform: "translateX(-50%)",
      width: "100%",
      zIndex: 10,

      "& .title": {
        fontSize: 18,
        lineHeight: "24px",
      },
    },
  }),

  researchWrapper: {
    alignItems: "flex-start",
    display: "flex",
    flexWrap: "wrap",
    height: 308,
  },
});
