export const Topics = [
  {
    id: null,
    name: "Select topic of interest",
    disabled: true,
  },
  {
    id: "Parent Pass as a customer perk",
    name: "Parent Pass as a customer perk",
  },
  {
    id: "Parent Pass as an employee benefit",
    name: "Parent Pass as an employee benefit",
  },
  {
    id: "Interested in both",
    name: "Interested in both",
  },
  {
    id: "Something else",
    name: "Something else",
  },
];
