import { Interpolation } from "@emotion/react";

import JpgBanner from "./assets/main-background.jpg";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  root: (theme) => ({
    color: "white",
    fontSize: 21,
    fontWeight: 600,
    maxWidth: "100vw",
    overflow: "hidden",
    paddingTop: 56,

    [theme.mediaRequests.mobile]: {
      fontSize: 16,
      fontWeight: 400,
    },
  }),

  pageContainer: {
    margin: "0 auto",
    maxWidth: 1240,
    padding: "0 20px",
  },

  pageTitle: (theme) => ({
    color: theme.palette.text.primary,
    fontSize: 24,
    lineHeight: "30px",
    marginBottom: 20,
    textAlign: "center",

    [theme.mediaRequests.notMobile]: {
      fontSize: 30,
    },
  }),

  mainBlock: (theme) => ({
    backgroundColor: "#19191b",
    backgroundImage: `url(${JpgBanner})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    borderBottom: `7px solid ${theme.palette.yellow.main}`,
    borderRadius: "0 0 65% 65%/ 25%",
    height: "calc(100vh - 56px)",
    position: "relative",

    [theme.mediaRequests.mobile]: {
      borderRadius: "0 0 90% 90%/ 15%",
    },
  }),

  mainBlockContent: {
    alignItems: "center",
    color: "white",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "space-around",
    padding: "50px 20px",
  },

  mainHeading: (theme) => ({
    fontSize: 54,
    fontWeight: 700,
    marginBottom: 0,
    marginTop: 0,
    textAlign: "center",
    whiteSpace: "pre-wrap",

    [theme.mediaRequests.mobile]: {
      fontSize: 28,
    },
  }),

  mainSubheading: (theme) => ({
    fontWeight: 700,
    textAlign: "center",

    [theme.mediaRequests.notMobile]: {
      fontSize: 28,
      marginBottom: 70,
    },

    [theme.mediaRequests.mobile]: {
      fontSize: 21,
      marginTop: 5,
      whiteSpace: "pre-wrap",
    },
  }),

  splittedSection: (theme) => ({
    padding: "50px 0",

    [theme.mediaRequests.notMobile]: {
      alignItems: "flex-start",
      display: "flex",
      justifyContent: "center",
      padding: "140px 0",

      "&.covid": {
        alignItems: "stretch",
      },

      "& > div": {
        width: "50%",

        "&:first-of-type": {
          paddingRight: 65,
        },

        "&:last-child": {
          paddingLeft: 65,
        },
      },
    },

    [theme.mediaRequests.mobile]: {
      display: "flex",
      flexWrap: "wrap",

      "& > div": {
        width: "100%",
      },

      "& .mobile-first": {
        marginBottom: 40,
        order: 1,

        "&.short": {
          marginBottom: 24,
        },
      },

      "& .mobile-second": {
        order: 2,
      },
    },
  }),

  sectionHeading: (theme) => ({
    fontSize: 36,
    fontWeight: 700,
    marginBottom: 60,
    whiteSpace: "pre-wrap",

    "&.gold": {
      color: theme.palette.yellow.main,
    },

    "&.black": {
      color: "#131314",
    },

    [theme.mediaRequests.notMobile]: {
      "&.claim": {
        whiteSpace: "nowrap",
      },
    },

    [theme.mediaRequests.mobile]: {
      fontSize: 21,
      marginBottom: 35,
      textAlign: "center",

      "&.support": {
        textAlign: "left",
      },
    },
  }),

  highlightedParagraph: (theme) => ({
    paddingTop: 10,
    position: "relative",

    "&:before": {
      backgroundColor: theme.palette.yellow.main,
      content: "''",
      display: "block",
      height: 2,
      left: 0,
      position: "absolute",
      top: 0,
      width: 80,

      [theme.mediaRequests.mobile]: {
        width: 30,
      },
    },

    "&:not(:last-child)": {
      marginBottom: 80,

      [theme.mediaRequests.mobile]: {
        marginBottom: 30,
      },
    },

    "&.black": {
      color: theme.palette.text.primary,

      "&:before": {
        backgroundColor: theme.palette.text.primary,
      },
    },

    "& .item": {
      fontSize: "inherit",
      fontWeight: "inherit",
      paddingLeft: 30,
      position: "relative",

      "&:before": {
        content: "'—'",
        position: "absolute",
        left: 0,
      },
    },
  }),

  lightBlackBackground: {
    backgroundColor: "#19191b",
  },

  darkBlackBackground: {
    backgroundColor: "#131314",
  },

  parentDayOutStepsContainer: (theme) => ({
    "&.isServicePage": {
      backgroundColor: theme.palette.text.primary,
      borderRadius: 17,
      color: "white",
      padding: 32,
    },

    "&:not(.isServicePage)": {
      [theme.mediaRequests.notMobile]: {
        marginTop: 109,
      },

      [theme.mediaRequests.mobile]: {
        marginBottom: 36,
      },
    },
  }),

  parentDayOutStep: (theme) => ({
    alignItems: "center",
    display: "flex",

    "& img": {
      height: 70,
      marginRight: 30,
      width: 70,

      [theme.mediaRequests.mobile]: {
        height: 40,
        marginRight: 20,
        width: 40,
      },
    },

    "& .text": {
      borderBottom: `2px solid ${theme.palette.yellow.main}`,
      flex: 1,
      paddingBottom: 12,
    },

    "&:not(:last-child)": {
      marginBottom: 48,

      [theme.mediaRequests.mobile]: {
        marginBottom: 38,
      },
    },
  }),

  goldBackground: (theme) => ({
    backgroundColor: theme.palette.yellow.main,

    "&.faq": {
      [theme.mediaRequests.notMobile]: {
        marginTop: -660,
      },

      [theme.mediaRequests.mobile]: {
        marginTop: -50,
      },
    },

    "&.employee": {
      [theme.mediaRequests.notMobile]: {
        marginTop: -730,
      },
    },

    "&.services": {
      [theme.mediaRequests.notMobile]: {
        marginTop: -580,
      },

      [theme.mediaRequests.mobile]: {
        marginTop: -50,
      },

      "& .brochure-button": {
        marginBottom: 50,
      },
    },
  }),

  supportWrapper: (theme) => ({
    padding: "140px 0",

    [theme.mediaRequests.mobile]: {
      padding: "40px 0",
    },
  }),

  parentPassTitleWrapper: (theme) => ({
    paddingTop: 140,

    [theme.mediaRequests.mobile]: {
      paddingTop: 40,
    },
  }),

  goldButton: (theme) => ({
    borderRadius: 12,
    marginTop: 16,
    padding: "15px 25px",

    [theme.mediaRequests.mobile]: {
      padding: "10px 25px",
      width: "100%",
    },
  }),

  field: {
    color: "white !important",

    "&:before": {
      borderBottomColor: "white !important",
    },

    "&:after": {
      borderBottomColor: "white !important",
    },

    "& input": {
      "&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus":
        {
          WebkitBoxShadow: "0 0 0 30px #131314 inset !important",
          WebkitTextFillColor: "white",
          backgroundColor: "#131314",
        },
    },
  },

  stateSliderDescription: {
    "&:not(:last-child)": {
      marginBottom: 16,
    },
  },
});
