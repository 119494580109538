import React from "react";

import img from "@images/australia.jpeg";
import { createCss } from "./styles";
import { AustraliaBlockProps } from "./types";
import { format_number } from "@helpers/format_number";
import { Models } from "@services/api";

export const AustraliaBlock: React.FC<AustraliaBlockProps> = ({
  onClick,
  counters,
}) => {
  const css = createCss();

  return (
    <div css={css.root} onClick={() => onClick([144.9605403, -37.8159913])}>
      <div className="image-container">
        <img
          src={img}
          alt="australia"
          css={{
            width: "100%",
          }}
        />
      </div>
      <div className="counters-container">
        <div className="title">Australia</div>
        {counters.AUS.counters?.[Models.FacilityTypeID.DaycareCenter] && (
          <div>
            Long day care:{" "}
            {format_number(
              counters.AUS.counters?.[Models.FacilityTypeID.DaycareCenter],
            )}
          </div>
        )}
        {counters.AUS.counters?.[Models.FacilityTypeID.PreSchool] && (
          <div>
            Preschools:{" "}
            {format_number(
              counters.AUS.counters?.[Models.FacilityTypeID.PreSchool],
            )}
          </div>
        )}
        {counters.AUS.counters?.[Models.FacilityTypeID.Oshc] && (
          <div>
            OSHC:{" "}
            {format_number(counters.AUS.counters?.[Models.FacilityTypeID.Oshc])}
          </div>
        )}
      </div>
    </div>
  );
};
