import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  root: {
    cursor: "pointer",
    fontWeight: 700,
    position: "relative",
    marginTop: 40,

    "& .image-container": {
      backgroundPosition: "center",
      backgroundSize: "100% 100%",
      position: "relative",
      width: "100%",

      "&:after": {
        background:
          "radial-gradient( ellipse at center,rgba(0,0,0,0.1) 0%,rgba(0,0,0,0) 100% ) rgba(0,0,0,0.3)",
        bottom: 5,
        content: "''",
        display: "block",
        left: 0,
        position: "absolute",
        right: 0,
        top: 0,
      },
    },

    "& .counters-container": {
      top: "50%",
      transform: "translateY(-50%)",
      color: "white",
      fontSize: 14,
      left: 0,
      lineHeight: "24px",
      padding: 10,
      position: "absolute",
      textAlign: "center",
      width: "100%",

      "& .title": {
        fontSize: 18,
        lineHeight: "24px",
        marginBottom: 24,
      },
    },
  },
});
