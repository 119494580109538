import { createUseStyles } from "react-jss";

import { ITheme } from "@theme";

const styles = (theme: ITheme) => ({
  root: {},

  textarea: {
    minHeight: 163,
  },

  grid: {
    paddingBottom: "0 !important",
    paddingTop: "0 !important",
  },

  gridContainer: {
    marginBottom: "0 !important",
    marginTop: "0 !important",
  },

  successMessage: {
    color: "white",
    textAlign: "center",
    width: "100%",
  },

  blackButton: {
    padding: "17px 25px",

    [theme.mediaRequests.mobile]: {
      padding: "9px 25px",
    },
  },

  field: {
    background: "white",
    borderRadius: 12,
    padding: "14px 20px",

    "& input": {
      "&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus":
        {
          "-webkit-box-shadow": `0 0 0 30px white inset !important`,
          backgroundColor: "white",
        },
    },

    "&:before": {
      content: "none !important",
    },

    "&:after": {
      content: "none !important",
    },

    "& fieldset": {
      display: "none",
    },

    [theme.mediaRequests.mobile]: {
      padding: "9px 13px",
    },
  },
});

export default createUseStyles(styles, { name: "SupportForm" });
