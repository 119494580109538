import React from "react";

import Typography from "@ui-kit/Typography";
import { t } from "@i18n";
import { createCss } from "../styles";

export const AboutBlock: React.FC = () => {
  const css = createCss();

  return (
    <div css={css.goldBackground}>
      <div css={css.pageContainer}>
        <div css={css.splittedSection}>
          <div>
            <div css={css.sectionHeading} className="black">
              {t("homepage.aboutBlock.title")}
            </div>
            <Typography
              className="black"
              css={css.highlightedParagraph}
              variant="inherit"
              component="div"
              align="justify"
            >
              <Typography variant="inherit" align="justify" paragraph>
                {t("homepage.aboutBlock.paragraph1")}
              </Typography>
              <Typography variant="inherit" align="justify" paragraph>
                {t("homepage.aboutBlock.paragraph2")}
              </Typography>
              <Typography variant="inherit" align="justify">
                {t("homepage.aboutBlock.paragraph3")}
              </Typography>
            </Typography>
          </div>

          <div></div>
        </div>
      </div>
    </div>
  );
};
