import React, { Fragment } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import values from "lodash/values";
import times from "lodash/times";
import clsx from "clsx";

import { createCss } from "./styles";
import { Slider } from "@ui-kit/Slider";
import { ExtendedState, StateCounters } from "../types";
import { ReactGAEventWait } from "@helpers/ga";
import { Models } from "@services/api";
import { schoolTypesMap } from "@constants/school-types";
import { useViewport } from "@hooks/useViewport";

const renderStateItem = (s: ExtendedState) => {
  return (
    <Fragment>
      <div className="image-container">
        {s.imageUrl !== null && (
          <LazyLoadImage
            height="100%"
            width="100%"
            src={s.imageUrl}
            alt={s.name}
          />
        )}
      </div>
      {s.counters && (
        <div className="counters-container">
          <div>
            {schoolTypesMap[Models.FacilityTypeID.DaycareCenter].title}s:{" "}
            {s.counters[Models.FacilityTypeID.DaycareCenter].toLocaleString()}
          </div>
          <div>
            {schoolTypesMap[Models.FacilityTypeID.PrivateSchool].title}s:{" "}
            {s.counters[Models.FacilityTypeID.PrivateSchool].toLocaleString()}
          </div>
          {s.counters[Models.FacilityTypeID.PreSchool] > 0 && (
            <div>
              {schoolTypesMap[Models.FacilityTypeID.PreSchool].title}s:{" "}
              {s.counters[Models.FacilityTypeID.PreSchool].toLocaleString()}
            </div>
          )}
        </div>
      )}
      <div className="text-container">
        <div className="title">{s.name}</div>
      </div>
    </Fragment>
  );
};

type StateSliderProps = {
  counters: StateCounters;
  onClick: (coords: number[] | null) => void;
};

export const StateSlider: React.FC<StateSliderProps> = ({
  counters,
  onClick,
}) => {
  const { isMobile } = useViewport();
  const css = createCss();

  const states = values(counters).filter(
    (s) =>
      s.counters !== null &&
      s.id !== "AUS" &&
      (s.counters.total > 1500 || s.id === "DC"),
  );

  if (isMobile) {
    return (
      <div css={css.stateSliderWrapper}>
        <Slider
          slidesToShow={1}
          slidesToScroll={1}
          css={css.statesSlider}
          lazyLoad="ondemand"
          afterChange={() => {
            ReactGAEventWait({
              action: "StatesSliderSwipe",
              category: "Homepage",
            });
          }}
          arrows={false}
          centerMode
          centerPadding="40px"
          initialSlide={states.findIndex((s) => s.id === "TX")}
        >
          {states.map((s) => {
            return (
              <div key={s.id}>
                <div
                  css={css.researchItem}
                  onClick={() => {
                    ReactGAEventWait({
                      action: "StateSliderClick",
                      category: "Homepage",
                      label: s.id,
                    });
                    onClick(s.defaultCoords);
                  }}
                >
                  {renderStateItem(s)}
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    );
  }

  return (
    <div css={css.stateSliderWrapper}>
      <Slider
        slidesToShow={1}
        slidesToScroll={1}
        css={css.statesSlider}
        arrows
        lazyLoad="ondemand"
        data-test="states-slider"
        dots={false}
        afterChange={() => {
          ReactGAEventWait({
            action: "StatesSliderSwipe",
            category: "Homepage",
          });
        }}
      >
        {[counters.NY, counters.IL, counters.CA, counters.TX]
          .concat(states)
          .filter((s) => Boolean(s.counters))
          .reduce((acc, s, i) => {
            const chunkIndex = Math.floor(i / 4);

            if (!acc[chunkIndex]) {
              acc[chunkIndex] = [];
            }

            acc[chunkIndex].push(s);

            return acc;
          }, [] as ExtendedState[][])
          .map((chunk, i) => {
            return (
              <div key={i}>
                <div css={css.researchWrapper}>
                  <Fragment>
                    {chunk.map((s, i) => (
                      <div
                        css={css.researchItem}
                        className={clsx({
                          empty: !s.imageUrl,
                        })}
                        onClick={() => onClick(s.defaultCoords)}
                        key={`${s.id}-${i}`}
                      >
                        {renderStateItem(s)}
                      </div>
                    ))}

                    {times(4 - chunk.length, (i) => (
                      <div css={css.researchItem} className="empty" key={i}>
                        More locations <br /> coming soon
                      </div>
                    ))}
                  </Fragment>
                </div>
              </div>
            );
          })}
      </Slider>
    </div>
  );
};
